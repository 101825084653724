import {useState, useContext } from 'react'

import styles from './MobileMenu.module.scss'
import cx from 'classnames'
import ScrollLink from 'components/ScrollLink'
import { GlobalContext } from 'store/GlobalContext'
import { DOWNLOAD_CENTER } from 'store/Constants'

const Footer = () => {

  const [open, setOpen] = useState(false)

  const {store} = useContext(GlobalContext);

  return (
    <div className={cx(styles.mobileMenu, open && styles.open)}>
      <button onClick={() => setOpen(!open)} className={cx(styles.menuButton, open && styles.close)}>
        <span></span>
        <span></span>
        <span></span>
      </button>
      <nav>
      <ScrollLink id="hero" click={() => setOpen(false)} className={cx(styles.navLink, store.view === 'hero' && styles.active)}>Home</ScrollLink>
        <ScrollLink id="partner" click={() => setOpen(false)} className={cx(styles.navLink, store.view === 'partner' && styles.active)}>Safety in Productions</ScrollLink>
        <ScrollLink id="icons" click={() => setOpen(false)} className={cx(styles.navLink, store.view === 'icons' && styles.active)}>Lifesavers</ScrollLink>
        <ScrollLink id="resources" click={() => setOpen(false)} className={cx(styles.navLink, store.view === 'resources' && styles.active)}>Additional Resources</ScrollLink>
        <hr />
        <a href={DOWNLOAD_CENTER} target="_blank" rel="noreferrer" className={styles.navTitle}>Download Center</a>
      </nav>
    </div>
  )
}

export default Footer