import React, { useEffect, useRef } from 'react'
import gsap from 'gsap/all'

import layout from 'styles/modules/Layout.module.scss'
import styles from './Resources.module.scss'
import cx from 'classnames'
import Footer from 'components/Footer'

import Resource1Image from 'assets/img/resource1.png'
import Resource2Image from 'assets/img/resource2.png'
import Resource3Image from 'assets/img/resource3.png'

import { ReactComponent as DownloadIcon } from 'assets/svg/download.svg'
import { ReactComponent as LinkIcon } from 'assets/svg/link.svg'
import { ICON_TEXT, TOOLKIT } from 'store/Constants'
import { useState } from 'react'
import useWindowSize from 'hooks/useWindowSize'
import IconModal from 'components/Modal/IconModal'



const Resources = () => {

  const rootView = useRef(null)

  const [minHeight,setMinHeight] = useState(100)
  const windowSize = useWindowSize()

  useEffect(() => {
    if(rootView.current) {

      gsap.timeline({
        scrollTrigger: {
          trigger: rootView.current,
          start: "top 60%",
          end: "bottom bottom",
          scrub: true,
        }
      })
        .fromTo('.gs-resource-header', {
          opacity:0,
          y: '10vh'
        },{
          opacity: 1,
          duration: 2,
          ease: 'power2.out',
          y: 0,
        })
        .fromTo('.gs-resource-item', {
          opacity:0,
          y: '6vh'
        },{
          duration: .75,
          stagger: .5,
          opacity: 1,
          y: 0,
        }, "-=1.75")
        .to('.gs-resource-item', {
          duration: .5,
          opacity: 1
        })
    }
  },[])

  useEffect(() => {
    if(rootView.current) {
      let height = 0
      const items = rootView.current.querySelectorAll('.js-equal-height p')
      items.forEach((item) => {
        height = Math.max(height, item.clientHeight)
      })
      setMinHeight(height)
    }
  },[rootView, windowSize])


  return (
    <div ref={rootView} className={cx(layout.slide, styles.root)}>
      <div className={cx(styles.container, layout.container)}>
        <div className={cx('gs-resource-header', styles.header)}>
          <h2>Additional Resources</h2>
        </div>
        <div className={styles.grid}>
          

          <div className={cx('gs-resource-item', styles.item)}>
            <div className={styles.imageWrapper}>
              <img src={Resource2Image} alt="Resource" />
            </div>
            <h3>Download Center</h3>
            <div className='js-equal-height' style={{height: minHeight, marginBottom: '20px'}}>
              <p className="lg">Gain access to downloading different icon assets in a variety of file formats.</p>
            </div>
            <a className="btn" href="https://sites.google.com/netflix.com/netflixemeaprodhealthandsafety/" target="_blank" rel="noreferrer" >
              Visit
              <LinkIcon />
            </a>
          </div>
          
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Resources