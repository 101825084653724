import React from 'react'

import layout from 'styles/modules/Layout.module.scss'
import styles from './Disclaimer.module.scss'
import cx from 'classnames'

const Disclaimer = () => {

  return (
    <div className={cx(layout.slide, styles.root)}>
      <div className={cx(layout.container, styles.container)}>
        <div className={cx('gs-disclaimer', styles.box)}>
          <p><strong>Important</strong>: The resources on this website are for Netflix partner-managed productions originating from EMEA, UK, and APAC. These resources should not be used for partner-managed productions working out of, or originating from, the United States or Canada. Please contact your Netflix Production Partner for more information.</p>
        </div>
      </div>
    </div>
  )
}

export default Disclaimer