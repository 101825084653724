import React, { useRef } from 'react'

import layout from 'styles/modules/Layout.module.scss'
import styles from './Icons.module.scss'
import Layout from 'styles/modules/Layout.module.scss'

import cx from 'classnames'

import { ReactComponent as DownloadIcon } from 'assets/svg/download.svg'
import IconImage from 'assets/img/icons.png'
import { useEffect } from 'react'
import gsap, { ScrollTrigger } from 'gsap/all'
import { ICONS } from 'store/Constants'

const Icons = () => {

  const rootView = useRef(null)
  
  useEffect(() => {
    if(rootView.current) {

      ScrollTrigger.create({
        trigger: rootView.current,
        start: "top bottom-=15%",
        end: "bottom top",
        scrub: true,
        animation: gsap.fromTo('.gs-icon-content',{
          y:"40%",
        }, {
          y: "-25%",
          ease: "linear.none",
        })
      });

    }
  },[rootView])


  return (
    <div ref={rootView} className={cx(layout.slide, styles.root)}>
      <div className={Layout.container}>
        <div className={styles.grid}>
          <div className={cx(styles.itemImage, styles.item)}>
            <div className="gs-icon-image">
              <img src={IconImage} alt="Icons" />
            </div>
          </div>
          <div className={cx('gs-icon-content', styles.item)}>
            <h2>Lifesavers</h2>
            <p>
            In 2021, we carried out a global research project in partnership with the London School of Economics to better understand the most common significant  safety risks in our industry. We received feedback from more than 1,000 people working in productions across 6 different countries. This work helped us create the Lifesavers.
            </p>
            <p>
            The Lifesavers promote straightforward conversations that can be directly linked to the most common significant safety risks in our productions. They touch on key behaviors that can prevent fatal injuries during production activities.
            </p>
            <p>There are eight Lifesavers. Each Lifesaver is a combination of an image, complemented with text that explains what each of us can do to keep the production safe.</p>
            <a href="https://drive.google.com/drive/u/0/folders/1c4cx9Qu-svcWmyW1rj6S2Jf9M_THwDM-" target="_blank" rel="noreferrer" className="btn">
              DOWNLOAD
              <DownloadIcon />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Icons