import React, { useContext } from 'react'

import styles from './Header.module.scss'
import cx from 'classnames'
import Logo from 'assets/img/logo.png'

import useScrollPosition from 'hooks/useScrollPosition'
import { GlobalContext } from 'store/GlobalContext'
import ScrollLink from 'components/ScrollLink'
import { DOWNLOAD_CENTER } from 'store/Constants'

const OFFSET = 100
const Header = () => {

  const scrollPosition = useScrollPosition();

  const {store} = useContext(GlobalContext);

  return (
    <header className={cx(styles.header, scrollPosition > OFFSET && styles.filled)}>
      <div className={cx(styles.inner)}>
        <div className={styles.left}>
          <div className={styles.logo}>
            <ScrollLink id="hero">
              <img src={Logo} alt="Netflix" />
            </ScrollLink>
          </div>
          <nav>
            <ScrollLink id="partner" className={cx(styles.navLink, store.view === 'partner' && styles.active)}>Safety in Productions</ScrollLink>
            <ScrollLink id="icons" className={cx(styles.navLink, store.view === 'icons' && styles.active)}>Lifesavers</ScrollLink>
            <ScrollLink id="resources" offset={-80} className={cx(styles.navLink, store.view === 'resources' && styles.active)}>Additional Resources</ScrollLink>
          </nav>
        </div>
        <div className={styles.right}>
          <a href="https://sites.google.com/netflix.com/netflixemeaprodhealthandsafety/" className={styles.navTitle} target="_blank" rel="noreferrer" >Download Center</a>
        </div>
      </div>
    </header>
  )
}

export default Header